/*
================================================================================
Stylesheet Bestattungshaus-Fieber (webfont.css)
Theme Rocksolid Oneo
lokale Webfonts in eigener Datei laden
benutzt wird die library Bourbon
================================================================================
*/
/*
-------------------------------------------------
Google Fonts lokal einbinden
-------------------------------------------------
*/
@font-face {
  font-family: "Open Sans";
}

@font-face {
  font-family: "Open Sans";
}

@font-face {
  font-family: "Open Sans";
}

@font-face {
  font-family: "Open Sans";
}
