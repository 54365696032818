/*
-------------------------------------------------
Google Fonts lokal einbinden
-------------------------------------------------
*/


// Open Sans
@include font-face("Open Sans", "../fonts/open-sans-v15-latin/open-sans-v15-latin-regular",400, normal);
@include font-face("Open Sans", "../fonts/open-sans-v15-latin/open-sans-v15-latin-700", 700, normal);
@include font-face("Open Sans", "../fonts/open-sans-v15-latin/open-sans-v15-latin-italic", 400, italic);
@include font-face("Open Sans", "../fonts/open-sans-v15-latin/open-sans-v15-latin-700italic", 700, italic);
