@charset 'UTF-8';
/*
================================================================================
Stylesheet Bestattungshaus-Fieber (webfont.css)
Theme Rocksolid Oneo
lokale Webfonts in eigener Datei laden
benutzt wird die library Bourbon
================================================================================
*/

// Libraries und Normalisierung
@import 'bourbon';

@import 'general/font';
